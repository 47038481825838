import React, { Component, Fragment } from "react";
import { Container, Icon, Image } from "semantic-ui-react";
import { openModal } from "../../../app/modals/modalActions";
import { compose } from "redux";
import { connect } from "react-redux";
import "./homePage.css";
import { Helmet } from "react-helmet";
import LoginForm from "../loginModal/LoginForm";
import { isMobileView } from "../../../app/common/utils/common";
import FullBanner from "../../pageContent/FullBanner";

const actions = {
	openModal,
};

class HomePage extends Component {
	async componentDidMount() {
		// if (isLoggedIn()) {
		//   const role = getRole();
		//   this.props.history.push(`/${role.toLowerCase()}`);
		// }
	}

	handleShowLogin = () => {
		this.props.openModal("LoginModal", { hasLoggedIn: this.handleLoggedIn });
	};

	// handleLoggedIn = () => {
	//   const role = getRole();
	//   this.props.history.push(`/${role.toLowerCase()}`);
	// };

	render() {
		return (
			<Fragment>
				<Helmet>
					<title>BabyYumYum - Login</title>
				</Helmet>
				<div className="homepage">
					<Container>
						<div className="fullBannerHomePage">
							<FullBanner />
							<br />
						</div>
						<div
							className="main-background"
							style={{ backgroundImage: "url('../assets/loginpagebaby1.jpg')" }}
						>
							<LoginForm />
							<div
								className={"welcome"}
								style={isMobileView() ? { position: "unset" } : null}
							>
								<div
									style={isMobileView() ? { paddingTop: 400, paddingLeft: 20 } : null}
								>
									<p className="textWelcome">
										Welcome to the MyMomentum BabyYumYum maternity benefits portal – the
										exclusive home to Momentum maternity members.
										<br />
										We look forward to being with you one baby step at a time.
									</p>

									<p className="textWelcome" style={{ color: "red", marginBottom: 15 }}>
										Please note that from 20 December 2024 till 9 January 2025, the
										BabyYumYum Bears will be on a holiday break. Please expect delays on
										all Swag Bags requested for dispatching, SMS delivery of meal
										vouchers, telephonic and email communication. We will be sure to
										attend to all queries and Mommy needs from 10 January 2025. Happy
										Holidays :)
									</p>

									<p className="textWelcome">
										<img src="../assets/whatsapp.png" /> For a summary of your baby's
										development and other juicy tidbits, <b>WhatsApp</b> our{" "}
										<b>MommyBot</b> on{" "}
										<a href="tel:087 153 1024">
											<b>087 153 1024</b>
										</a>{" "}
										and say Hi!
									</p>

									<p className="textWelcome">
										Once you have given birth, and you wish to book your nurse visit,
										please contact Momentum Wellness by emailing
										<a
											href="mailto:nursevisit@momentum.co.za"
											style={{ color: "#e01c21" }}
										>
											{" "}
											nursevisit@momentum.co.za
										</a>{" "}
										or calling
										<a href="tel:0860 117 859" style={{ color: "#e01c21" }}>
											{" "}
											0860 117 859
										</a>
									</p>

									<p
										className="textWelcome"
										style={{ color: "#ff66c4", marginBottom: 100 }}
									>
										<Icon name="heart" color="red" />
										the MyMomentumBabyYumYum team.
									</p>
								</div>
							</div>
						</div>
						{isMobileView && (
							<div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						)}
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default compose(connect(null, actions))(HomePage);
